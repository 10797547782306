<template>
  <div class="color-picker">
    <a-popover trigger="click" overlayClassName="color-picker-popover">
      <a-button class="color-btn" :style="style"></a-button>
      <div slot="content">
        <sketch-picker :value="color" @input="updateValue" />
      </div>
    </a-popover>
  </div>
</template>

<script>
import { Sketch } from "vue-color";

export default {
  data() {
    return {
      showColor: false
    };
  },

  model: {
    prop: "color",
    event: "change"
  },

  props: {
    color: String
  },
  computed: {
    style() {
      return {
        backgroundColor: this.color
      };
    }
  },

  methods: {
    toggleColorPicker() {
      this.showColor = !this.showColor;
    },

    updateValue(val) {
      this.$emit("change", val.hex8);
    }
  },

  components: {
    "sketch-picker": Sketch
  }
};
</script>

<style lang="scss">
.color-btn {
  width: 80px;
  margin: 5px 0;
}
.color-picker-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}
</style>
